.modal-content .title-class {
    font-family: "Manrope", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
}

.body-class {
    padding: 40px 50px;
}

.modal-footer-btn2 {
    background-color: #0000FF !important;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}

.modal-footer-btn1 {

    background-color: #FFFFFF !important;
    color: #0000FF !important;
    border: none !important;
}

.modal-footer-class {
    padding: 25px 45px;
}

.footer-text-modal {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

}
.add-goal-title{
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #666D80;
}

.text-area-goal{
    border: 1px solid #E8E9ED;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding: 5px 10px;
    outline: none;
}


.select-label {
    /* padding-top: 52px; */
    font-family: "Manrope", sans-serif;
    color: #666d80;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .select-class {
    height: 48px;
    border: 1px solid #e8e9ed !important;
    outline: none;
    border-radius: 0px !important;
    width: 100%;
    padding: 0px 20px;
font-family: "Manrope", sans-serif;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 19px !important;
letter-spacing: 0em;
text-align: left;

  }
  .drop-error-class{
    border: 1px solid red !important;

  }
  
  .select-class:focus {
    box-shadow: none !important;
    border: 1px solid #e8e9ed !important;
  }
  
.layout {
  height: 100vh;
}
.layout .d-layout {
  height: calc(100vh - 45px);
  display: flex;
  /* height: 20vh; */
}

.layout .d-layout .sidebar-class {
  flex: 18%;
  max-width: 220px;
}

.layout .d-layout .page-content {
  flex: 82%;
  overflow-y: auto;
  position: relative;
}

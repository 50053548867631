.input-label {
  /* padding-top: 52px; */
  font-family: "Manrope", sans-serif;
  color: #666d80;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.input-class {
  height: 48px;
  border: 1px solid #e8e9ed;
  outline: none;
  border-radius: 0px;
}
.error-class {
  height: 48px;
  border: 1px solid red !important;
  outline: none;
  border-radius: 0px !important;
}
.input-class:focus {
  box-shadow: none;
  border: 1px solid #e8e9ed;
}

.error-class:focus {
  /* border: 1px solid red !important; */
  box-shadow: none !important;
}

.error-text {
  color: red !important;
}

.header {
  background-color: #ffffff;
  border: 1px solid #e8e9ed;
  .container {
    margin: 0;
    max-width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .links {
    display: flex;
    gap: 0.75rem;
  }
  .logo-class {
    width: 206px;
  }
  .link-name {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    /* color: #0000ff; */
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    white-space: nowrap;

    //padding: 0.25rem 0;
  }
  .personal_growth {
    margin-left: 4rem;
  }

  .navbar-nav {
    .nav-link{
      color: #9398a7;

      padding: 0.75rem 1rem;

      &.active {
        box-shadow: 0 2px #0000ff;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(219, 223, 255, 0.73) 100%), #FFFFFF;
        .link-name {
          color: #0000ff;
        }
      }
    }
  }
  .super-admin-button-class {
    background-color: rgba(0, 0, 255, 1) !important;
    height: 44px !important;
    white-space: nowrap;
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    border-radius: 22px !important;
  }

  .logout-class {
    display: flex;
    margin-left: 1.5rem;
    cursor: pointer;
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #9398a7;
  }

  .logout-text-class {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
  }
}
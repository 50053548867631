.btn-class {
  border-radius: 0px !important;
  font-family: "Manrope", sans-serif;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 19px !important;
  /* letter-spacing: 0.1em; */
}

.btn-class:focus{
  box-shadow: none !important;
}
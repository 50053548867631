/* CSS for MultiValue Image */
.profile-pic-class-for-selected-options {
  height: 20px;
  width: 20px;
  margin: 0px 8px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile-pic-class-for-selected-options .label {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}

.input-class {
  height: 48px;
  border: 1px solid #e8e9ed;
  outline: none;
  border-radius: 0px;
}

.input-class:focus {
  box-shadow: none;
  border: 1px solid #e8e9ed;
}

.multi-select-error-class{
  border: 1px solid red;
}
.label-margin {
  margin-bottom: 50px;
}

.class-dropdown .objective-dropdown {
  flex: 50%;
  /* margin-right: 10px; */
}

.class-dropdown .date-dropdown {
  margin-left: 20px;
  flex: 50%;
}
.key-result-value {
  margin-top: -30px;
}
.class-dropdown .input-margin {
  margin-right: 20px;
}

.individual-class {
  margin-bottom: 50px;
}

.key-results {
  background-color: #f8f9fc;
  padding: 20px;
}

.add-key-btn {
  background-color: #ffffff !important;
  color: #0000ff !important;
  border: none !important;
  width: auto !important;
}

.border-btn {
  border: 1px solid #0000ff !important;
  border-style: dashed !important;
}

.index-class {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  background-color: #101f47;
  color: white;
}

.hr-class-1 {
  margin-top: 30px;
  border-top: 1px dashed #e8e9ed;
}

/* .hr-class-2{
  border: 1px solid #E8E9ED;
} */

.custom-key-margin {
  margin: 8px 6px;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.5;
}

.n-margin-profile {
  margin-left: -10px;
}

.profile-image-label {
  height: 20px;
  width: 20px;
  background-color: #101f47;
  margin: 0px 5px;
  border-radius: 50%;
  padding: 2px 6px;
}

.form-control {
  margin: 0px !important;
}

.form-select {
  margin: 0px;
}

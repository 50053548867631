.toggle-button-class {
  background-color: #ffffff !important;
  color: #101f47 !important;
  box-shadow: none !important;
}

.toggle-button-card-class {
  font-weight: 500;
  font-size: 14px;
  color: #101f47;
}

.common-height-class {
  height: 100%;
}

.center-alignment-class {
  display: flex;
  justify-content: center;
  align-items: center;
}

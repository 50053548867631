@import "src/assets/styles/utls";

.sidebar {
  border-right: 1px solid #e8e9ed;
  height: 100%;
  z-index: 100;
  /* justify-content: center; */
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-y: auto;
  .add-btn {
    margin: rem(24) rem(12) rem(24);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    font-size: rem(14);
    border-radius: rem(3);
    width: calc(100% - 24px);

    height: rem(48);

    background: #fff;
    color: $blue;

    svg {
      width: 20px;
      position: relative;
      transition: 0.5s ease;
      left: 0;
      path {
        stroke: $blue;
      }
    }
    &:hover {
      background-color: $blue;
      color: #fff;
      svg {
        left: -3px;
        path {
          stroke: #fff;
        }
      }
    }
  }
  .accord-class {
    width: 100%;
    height: fit-content;
    padding: 0;

    .accordion-header {
      .accordion-button {
        background: transparent;
        box-shadow: none;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        line-height: rem(48);
        padding: 0 12px;

        border-bottom: 1px solid #ddd;
      }
      .active {
        color: $blue;
      }
    }

    .accordion-button:not(.collapsed)::after,
    .accordion-button::after {
      background-size: 75%;
      background-position: center;
    }
  }


  .accordion-body {
    background: linear-gradient(172deg, #dbdfff -20%, #FFFFFF 20%), #FFFFFF;
    padding: rem(8) 12px;

    .list-item {
      font-family: "Manrope", sans-serif;
      font-size: 14px;
      line-height: 19px;
      color: #666d80;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .accordion-button:not(.collapsed) {
    color: black;
  }

  .accordion-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 0.75rem 0;

    border-bottom: 1px solid #e9e9e94f;

    &::after {
      content: "";
      background-image: url("../../assets/images/circle-icon.svg");
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
    }
    &.active {
      color: $blue;
      font-weight: 700;

      &::after {
        background-image: url("../../assets/images/active-circle-icon.png");
      }
    }
  }

}

.item-class {
  cursor: pointer;
  width: 100%;
  height: fit-content;
  padding: 16px 12px;
  border-bottom: 1px solid #e8e9ed;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  &.active {
    color: $blue;
    font-weight: 700;

  }
}


/* .accord-class .icon-class {
  background: url("../../assets/images/sales-icon.svg");
  width: 30px;
  background-repeat: no-repeat;
} */

.accord-class .name-icon {
  width: 80%;
}
.sidebar-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}




/* .accord-class .circle-icon-class {
  width: 10%;
  background-image: url("../../assets/images/circle-icon.svg");
  background-repeat: no-repeat;
} */



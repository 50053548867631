* {
    font-family: "Manrope";
}

::-webkit-scrollbar {
    width: 0px;
}

.qpbvp-page {
    padding: 1rem;
    background: linear-gradient(353deg, #FFFFFF 90%, rgba(219, 223, 255, 0.4) 100%), #FFFFFF
}

button, a {
    transform: scale(1);
    transition: all 0.5s ease-in-out;
    border-radius: 0;

}

button:active, a:active {
    transform: scale(0.98);
    transform-origin: center;
    box-shadow: none;
    outline: none;
}

button:focus {
    outline: none !important;
}

.nav-class {
    position: relative;
    z-index: 2;
}

.common-button {
    border-radius: 0.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    padding: 0;

    background: #010101;
    color: #fff;
    font-size: 14px;

    transition: ease-in-out 0.2s;

    text-decoration: none;
}

.common-button:hover {
    box-shadow: 0 0px 10px inset #0d0d1d22;
}

.common-button.button-has-icon {
    gap: 0.5rem;

}

.button-has-icon svg {
    transition: ease-in-out 0.2s;
    position: relative;
    left: 0;
}

.button-has-icon:not(.button-icon-only):hover svg {
    left: -2px;
}

.table-actions a, .table-actions button {
    background: none;
}

.common-button.text-button {
    background: none;
    color: #010101;
}